import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Loader from "./components/loader";
const View = lazy(() => import("./pages/view"));
const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const Work = lazy(() => import("./pages/work"));
const Contactform = lazy(() => import("./pages/contact"));

function App() {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<Loader />}>
            <View />
          </Suspense>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contactform />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
